.hero-text {
    margin-top: 20%;
}

.hero-image {
    background-image: url('../img/hero_image.jpeg');
    background-size: cover;
    background-position: center;
    background-blend-mode: darken;
}
