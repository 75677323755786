.wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 5px;
    flex-direction: column;
    justify-content: center;
}
.main {
    text-align: left;
}
.impressum .item {
    font-size: 18px;
    margin: 20px 0;
}
h1 {
    margin-left: 0;
}
h3 {
    font-size: 20px;
    margin: 20px 0 10px 0;
    padding: 0;
}
